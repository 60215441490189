.bold {
    color: rgb(27,167,173);
    font-weight: 500;
}

.close {
    width: 20px;
}

.justify {
    padding: 0 0.67em;
    text-align: justify;
}

.menu {
    margin-top: 0.67em;
    width: 50px;
}


.navbar {
    align-items: flex-start;
    background: #fff;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    height: 100%;
    justify-content: space-between;
    left: -100%;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0.67em 0;
    position: fixed;
    top: 0;
    width: 100%;

    transition: left 0.5s ease-in-out;
}

.navbar.open {
    left: 0;
}

.nav-img {
    margin-top: 0.67em;
}

.nav-item {
    display: flex;
    flex: 1;
    font-size: 1.5em;
    align-items: center;
    padding: 0 0.67em;
    width: 100%;
}

.nav-item:nth-child(odd) {
    background: rgba(27,167,173, 0.05);
}

.nav-logo {
    margin-right: 0.67em;
    width: 2em;
}

.profile {
    color: #282c34;
    position: relative;
    height: 100%;
}

.profile-header {
    align-items: center;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.17);
    display: flex;
    font-weight: 500;
    justify-content: space-between;
    padding: 0 0.67em 0.67em;
}

.profile-header.sticky {
    background: white;
    position: fixed;
    top: 0;
    width: calc(100% - 1.34em);
}

li:first-child {
    position: absolute;
    right: 0.67em;
}

@media screen and (min-width: 768px) {
    .close {
        display: none
    }

    .justify {
        text-align: center;
    }

    .menu {
        display: none
    }

    .nav-item {
        cursor: pointer;
        display: block;
    }

    .nav-item:nth-child(odd) {
        background: rgba(27,167,173, 0);
    }

    .nav-logo {
        display: none;
    }

    .navbar {
        flex-direction: row;
        height: auto;
        left: inherit;
        position: relative;
    }

    .navbar.sticky {
        margin-top: calc(92px + 0.67em);
        z-index: -1;
    }

    .profile-header {
        margin-bottom: 0.67em;
    }

    li {
        flex: 1;
    }
}
