.logo-round {
    border-radius: 15%;
}

.project {
    margin: 0;
    padding: 1em 0.67em;
}

.project:nth-child(odd) {
    background: rgba(0, 0, 0, 0.05);
}

.project-description {
    margin: 1em 0;
    text-align: justify;
}

.project-details {
    margin: 1em 0;
}

.project-logo {
    max-width: 250px;
}

@media screen and (min-width: 768px) {
    .project {
        align-items: center;
        display: flex;
    }

    .project-description {
        flex: 4;
    }

    .project-logo__container {
        flex: 1;
    }
}
