body {
  position: absolute;
  height: 100%;
  width: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

.app {
  font-family: 'Roboto', sans-serif;
  height: 100%;
  text-align: center;
  width: 100%;
}

.down-arrow {
  bottom: 0;
  cursor: pointer;
  margin-bottom: 0.67em;
  position: absolute;
  width: 70px;
}

.header {
  background-position: center bottom;
  background-image: url('img/blue-bg.jpg');
  background-attachment: fixed;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.role {
  font-style: italic;
}

.section {
  margin-bottom: 3em;
}

.section:nth-child(odd) {
  background: rgba(27,167,173, 0.05);
  padding: 0.67em;
}

.subtitle {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.17);
}

.title {
  margin-top: 0;
  padding-top: 0.67em;
}
